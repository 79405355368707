<template>
    <v-row class="mt-10">
        <v-col>
            <v-row v-if="!isLoggedIn" class="mt-10">
                <v-col>
                    <v-card
                        class="mx-auto"
                        max-width="480"
                        min-height="300"
                        color="appBackground"
                        flat
                    >
                        <v-card-title>
                            <h3 class="word__wrap text-center">
                                {{ $t(`project.notLogged`) }}
                            </h3>
                        </v-card-title>
                        <v-card-text
                            class="d-flex justify-center flex-column align-center"
                        >
                            <hero animation-is="" :size="200"></hero>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn
                                depressed
                                x-large
                                color="primary"
                                @click="register()"
                            >
                                {{ $t(`project.newProject.registerBtn`) }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else class="d-flex flex-column align-center">
                <div class="img-container mb-6">
                    <v-img
                        alt="hero character"
                        class="shrink"
                        contain
                        :src="require(`@/assets/heroes/${hero}.svg`)"
                        width="260"
                        transition="scale-transition"
                    ></v-img>
                </div>
                <div v-if="isLoggedIn" class="is-logged-in text-center mb-6">
                    <div class="mb-4">
                        <h3>{{ $t(`project.newProject.registeredUser`) }}</h3>
                    </div>
                    <v-btn
                        depressed
                        x-large
                        color="primary"
                        @click="newProject()"
                    >
                        {{ $t(`project.newProject.startNewBtn`) }}
                    </v-btn>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Hero from "@/components/Heroes/Hero.vue";

export default {
    name: "NewProject",

    components: { Hero },

    data() {
        return {
            //
        };
    },

    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
        ...mapState({
            hero: (state) => state.auth.hero,
        }),
    },

    methods: {
        register() {
            this.$router.push({ name: "Register" });
        },
        newProject() {
            this.$router.push({ name: "SelectProjectType" });
        },
    },
};
</script>

<style lang="scss" scoped>
.centered {
    position: relative;
    top: 50%;
}

.not-logged-in,
.is-logged-in {
    max-width: 500px;
}
</style>
